import { useEffect, useState } from 'react'
import { GetServerSidePropsContext } from 'next'
import useSWR from 'swr'
import styles from './index.module.scss'

import TrialHeaderCountdown from '../../layouts/TrialHeaderCountdown'
import TrialHeader from '../../layouts/TrialHeader'
import { LessonListContent } from '../../components/LessonListContent'
import { TrialLessonContent } from '../../components/TrialLessonContent'
import { AwardContent } from '../../components/AwardContent'

import { ContentType } from '../../types/trialLessons'
import { fetcher } from '../../utils/fetcher'
import { useCampaignAndCountdownStatus } from '../../utils/campaignCountdown'

type Props = { contentType: ContentType; currentUser: any }

const TrialLessons = ({ contentType, currentUser }: Props) => {
  const [jwtToken, setJwtToken] = useState('')
  const { isCountdownActive } = useCampaignAndCountdownStatus()
  const [currentUserEmail, setCurrentUserEmail] = useState<string | null>(null)
  const [currentUserBirthday, setCurrentUserBirthday] = useState<string | null>(
    null
  )
  const [currentUserPrefecture, setCurrentUserPrefecture] = useState<
    string | null
  >(null)

  const { data: jwt } = useSWR(
    `${process.env.NEXT_PUBLIC_API_URL}/api/v3/users/current/jwt`,
    fetcher
  )

  useEffect(() => {
    if (jwt && !jwt.error) {
      setJwtToken(jwt.body)
    }
  }, [jwt])

  useEffect(() => {
    if (currentUser) {
      const { email, birthday, prefecture } = currentUser
      if (email) setCurrentUserEmail(email)
      if (birthday) setCurrentUserBirthday(birthday)
      if (prefecture) setCurrentUserPrefecture(prefecture)
    }
  }, [currentUser])

  return (
    <>
      <div className={styles.container}>
        {/* NOTE: カウントダウンは動かすが、表示はさせたくないため下記を一時的にコメントアウトする
                  割引バナーの切り替えを11/17 20:00:00に行うためそれ以降はコメントアウトを外す
        */}
        {/* {isCountdownActive && <TrialHeaderCountdown />} */}
        <TrialHeader
          contentType={contentType}
          // isCountdownActive={isCountdownActive}
        />
        <AwardContent contentType={contentType} />
        <TrialLessonContent contentType={contentType} />
        <section
          className={styles.applyContainer}
          id="reservation-step-summary"
        >
          <LessonListContent
            contentType={contentType}
            jwtToken={jwtToken}
            currentUserEmail={currentUserEmail}
            currentUserBirthday={currentUserBirthday}
            currentUserPrefecture={currentUserPrefecture}
          />
        </section>
      </div>
    </>
  )
}

export const getServerSideProps = async ({
  query,
}: GetServerSidePropsContext) => {
  const { utm_campaign, utm_term, type } = query
  let contentType = 'onlyOtl'
  const onsParams = [utm_campaign, utm_term, type]
  if (onsParams.some(p => p?.includes('_ons'))) {
    contentType = 'recommendOns'
  }

  return {
    props: { contentType },
  }
}

export default TrialLessons
