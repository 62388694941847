import React from 'react'
import Image from 'next/image'
import styles from './index.module.scss'
import trialContent1 from '../../public/trial_content1.png'
import trialContent2 from '../../public/trial_content2.png'

const MAP = [
  {
    image: trialContent1,
    title: 'SHElikesの紹介',
    description:
      '卒業生紹介などを含めてSHElikesのサービス概要を説明いたします。',
  },
  {
    image: trialContent2,
    title: 'カウンセリング',
    description:
      '一人一人の理想のキャリアをお伺いし、SHElikesを使ってどのように実現していくかを一緒に考えます。',
  },
]

const TrialContents = () => {
  return (
    <ol className={styles.container}>
      {MAP.map(({ image, title, description }, i) => (
        <li className={styles.list} key={`${image.src}${i}`}>
          <div className={styles.listImage}>
            <Image
              width={98}
              height={90}
              src={image.src}
              alt={`${title}}の画像`}
            />
          </div>
          <div className={styles.listItem}>
            <span>{title}</span>
            <p>{description}</p>
          </div>
        </li>
      ))}
    </ol>
  )
}

export default TrialContents
